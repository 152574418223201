import * as React from "react";
import "./ClientsList.scss";
import PropTypes from "prop-types";
import Marquee from "react-fast-marquee";

const ClientsList = (props) => {
  const middleIndex = Math.floor(props.clients.length / 2);

  return (
    <section className="homepage-clients-list">
      <div className="container-lg">
        <header>
          <h4>{props.title}</h4>
        </header>
      </div>
      <div className="grid-wrapper">
        <Marquee speed={25} gradientWidth={10} gradientColor={[0, 0, 0]}>
          {props.clients.map((item, index) => {
            if (index < middleIndex) {
              return (
                <div key={`client-image-${index}`}>
                  <div className={`client-item`}>
                    <img
                      className="img-fluid"
                      src={item.file.url + "?q=80&w=400"}
                    />
                  </div>
                </div>
              );
            }
          })}
        </Marquee>
        <Marquee
          speed={25}
          gradientWidth={10}
          gradientColor={[0, 0, 0]}
          direction={"right"}
        >
          {props.clients.map((item, index) => {
            if (index >= middleIndex) {
              return (
                <div key={`client-image-${index}`}>
                  <div className={`client-item`}>
                    <img
                      className="img-fluid"
                      src={item.file.url + "?q=80&w=400"}
                    />
                  </div>
                </div>
              );
            }
          })}
        </Marquee>
      </div>
    </section>
  );
};

ClientsList.propTypes = {
  clients: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ClientsList;
