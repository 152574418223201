import * as React from "react";
import "./ModusOperandi.scss";
import CallToAction from "../../CallToAction/CallToAction";
import PropTypes from "prop-types";

const Modus = (props) => {
  return (
    <div className="modus">
      <div className="step">{props.step}</div>
      <div className="content">
        <p className="title">{props.title}</p>
        <p>{props.children}</p>
      </div>
    </div>
  );
};

Modus.propTypes = {
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string,
};

const ModusOperandi = (props) => {
  const modiList = props.modi.map((item, index) => {
    return (
      <Modus key={`modus-${index}`} step={index + 1} title={item.title}>
        {item.description}
      </Modus>
    );
  });

  return (
    <section className="homepage-modus-operandi container-lg">
      <header>
        <h3>{props.children}</h3>
      </header>
      <div className="modi-list">{modiList}</div>
      <footer>
        <CallToAction to={props.to} label={props.label} />
      </footer>
    </section>
  );
};

ModusOperandi.propTypes = {
  modi: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default ModusOperandi;
