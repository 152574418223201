import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query homepage {
      allContentfulHomepage {
        nodes {
          node_locale
          pageTitle
          heroImage {
            gatsbyImageData
          }
          heroDestination
          heroCtaLabel
          presentation {
            raw
          }
          leftTile {
            title
            label
            destination
            image {
              gatsbyImageData
              file {
                url
              }
            }
            bgColor
          }
          rightTile {
            title
            label
            destination
            image {
              gatsbyImageData
              file {
                url
              }
            }
            bgColor
          }
          rightTileRow1Background
          servicesTitle {
            raw
          }
          serviceList
          servicesCtaLabel
          servicesDestination
          methodTitle {
            raw
          }
          methods {
            title
            description
          }
          caseStudy {
            name
            value
            path
          }
          methodCtaDestination
          methodCtaLabel
          clientTitle
          clientImages {
            gatsbyImageData
            file {
              url
            }
          }
          heroText {
            raw
          }
          workWithUsTile1 {
            title
            destination
            label
            image {
              gatsbyImageData
              file {
                url
              }
            }
            bgColor
          }
          workWithUsTile2 {
            title
            destination
            label
            image {
              gatsbyImageData
              file {
                url
              }
            }
            bgColor
          }
          blogTitle
          posts {
            title
            image {
              gatsbyImageData
              file {
                url
              }
            }
            destination
            label
          }
          blogCtaLabel
          blogCtaDestination
          reportTitle
          reportSubtitle
          reportLink
          reportLinkLabel
          reportMainImage {
            gatsbyImageData
            title
          }
        }
      }

      allContentfulCaseHistoryItem {
        nodes {
          node_locale
          customerName
          customerLink
          customerImage {
            gatsbyImageData
          }
          customerDescription {
            customerDescription
          }
        }
      }
    }
  `);
};

export default query;
