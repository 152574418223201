import * as React from "react";
import "./HeroText.scss";
import PropTypes from "prop-types";

const HeroText = (props) => {
  return (
    <section className="homepage-hero-text">
      <div className="container-lg">
        <div className="content">{props.children}</div>
        <div className="arrow-icon">
          <i className="px-arrow-down" />
        </div>
      </div>
    </section>
  );
};

HeroText.propTypes = {
  children: PropTypes.array.isRequired,
};

export default HeroText;
