import * as React from "react";
import "./Services.scss";
import CallToAction from "../../CallToAction/CallToAction";
import PropTypes from "prop-types";

const Services = (props) => {
  const services = props.services.map((item, index) => (
    <p key={`service-${index}`}>{item}</p>
  ));

  return (
    <section className="homepage-services">
      <header>
        <h3 className="container-lg">{props.children}</h3>
        <div className="header-bg flex-grow-0 flex-shrink-0">
          <span className="spacer" />
        </div>
      </header>
      <div className="services-list container-lg">{services}</div>
      <footer className="container-lg">
        <CallToAction to={props.to} label={props.label} />
      </footer>
    </section>
  );
};

Services.propTypes = {
  children: PropTypes.element.isRequired,
  services: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Services;
