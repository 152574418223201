import * as React from "react";
import "./CallToAction.scss";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const ConditionalWrapper = (props) => {
  if (props.mailTo === false) {
    return (
      <>
        {props.to ? (
          <Link to={props.to} className={props.className}>
            {props.children}
          </Link>
        ) : (
          ""
        )}
      </>
    );
  } else {
    return (
      // eslint-disable-next-line
      <a href={props.to} className={props.className} target="_blank">
        {props.children}
      </a>
    );
  }
};

ConditionalWrapper.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.element,
  mailTo: PropTypes.bool,
};

ConditionalWrapper.defaultProps = {
  mailTo: false,
};

const CallToAction = (props) => {
  let classes = "call-to-action ";
  if (props.inverted) classes += "inverted ";
  if (props.underlined) classes += "underlined ";
  if (props.className !== undefined && props.className !== "undefined")
    classes += props.className;
  if (props.mailTo === undefined || props.mailTo === "undefined")
    props.mailTo = false;

  if (props.to !== "") {
    return (
      <ConditionalWrapper
        className={classes}
        to={props.to}
        mailTo={props.mailTo}
      >
        <>
          <span>{props.label}</span>
          <i className="px-arrow-right" />
        </>
      </ConditionalWrapper>
    );
  }
};

CallToAction.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  mailTo: PropTypes.bool,
  className: PropTypes.string,
  underlined: PropTypes.bool,
};

CallToAction.defaultProps = {
  inverted: false,
  mailTo: false,
  underlined: true,
};

export default CallToAction;
