import * as React from "react";
import "./Tiles.scss";
import Tile from "./Tile";
import PropTypes from "prop-types";

const Tiles = (props) => {
  return (
    <section className="homepage-tiles">
      <div className="tiles left">
        <Tile
          title={props.leftTile.title}
          label={props.leftTile.label}
          image={props.leftTile.image}
          to={props.leftTile.destination}
        />
      </div>
      <div className="tiles right">
        <Tile addWrapper withBg bgDirection="right">
          <div
            className="tile-bg"
            style={{ backgroundColor: props.decorationBg }}
          />
        </Tile>
        <Tile
          title={props.rightTile.title}
          label={props.rightTile.label}
          image={props.rightTile.image}
          to={props.rightTile.destination}
          contentBgColor={props.rightTile.bgColor}
          addWrapper
          withBg
          bgPieces
        />
      </div>
    </section>
  );
};

Tiles.propTypes = {
  leftTile: PropTypes.object.isRequired,
  rightTile: PropTypes.object.isRequired,
  decorationBg: PropTypes.string.isRequired,
};

export default Tiles;
