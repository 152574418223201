import * as React from "react";
import PropTypes from "prop-types";
import CallToAction from "../../CallToAction/CallToAction";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useState } from "react";

const ConditionalWrapper = (props) => {
  return (
    <>
      {props.shouldAdd ? (
        <div className="wrapper">{props.children}</div>
      ) : (
        props.children
      )}
    </>
  );
};

ConditionalWrapper.propTypes = {
  shouldAdd: PropTypes.bool.isRequired,
  children: PropTypes.element,
};

const Tile = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleBoxHover = (e) => {
    e.preventDefault();
    setIsHovered(!isHovered);
  };
  let componentClass = "tile";
  if (props.withBg) componentClass += " with-bg";
  if (props.bgDirection)
    componentClass += ` ${
      props.bgDirection === "left" ? "bg-left" : "bg-right"
    }`;

  return (
    <div
      className={componentClass}
      onMouseEnter={handleBoxHover}
      onMouseLeave={handleBoxHover}
    >
      <ConditionalWrapper shouldAdd={props.addWrapper}>
        <>
          {props.title && (
            <Link
              to={props.to}
              style={{ backgroundColor: props.contentBgColor }}
              className="content"
            >
              <p className="title">{props.title}</p>
              <CallToAction
                label={props.label}
                className={isHovered ? "active" : ""}
              />
              {props.image && (
                <GatsbyImage
                  className="image img-fluid"
                  image={getImage(props.image)}
                  alt={props.title}
                />
              )}
            </Link>
          )}
          {props.image && !props.bgPieces && (
            <div
              className="image"
              style={{ backgroundImage: "url(" + props.image.file.url + ")" }}
            />
          )}
          {props.image && props.bgPieces && (
            <div className="image in-pieces">
              <div
                style={{ backgroundImage: "url(" + props.image.file.url + ")" }}
                className="top-top-piece piece"
              />
              <div
                style={{ backgroundImage: "url(" + props.image.file.url + ")" }}
                className="top-middle-piece piece"
              />
              <div
                style={{ backgroundImage: "url(" + props.image.file.url + ")" }}
                className="bottom-middle-piece piece"
              />
              <div
                style={{ backgroundImage: "url(" + props.image.file.url + ")" }}
                className="bottom-bottom-piece piece"
              />
            </div>
          )}
        </>
      </ConditionalWrapper>
      {props.children}
    </div>
  );
};

Tile.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  image: PropTypes.object,
  bgDirection: PropTypes.oneOf(["left", "right"]),
  to: PropTypes.string,
  addWrapper: PropTypes.bool,
  withBg: PropTypes.bool,
  contentBgColor: PropTypes.string,
  bgPieces: PropTypes.bool,

  label: function (props, propName) {
    if (
      props["to"] != undefined &&
      (props[propName] == undefined || typeof props[propName] != "string")
    ) {
      return new Error("A label is required if the to poperty is defined.");
    }
  },
};

Tile.defaultProps = {
  addWrapper: false,
  withBg: false,
  bgPieces: false,
};

export default Tile;
