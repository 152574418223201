import * as React from "react";
import "./WorkWithUs.scss";
import CallToAction from "../../CallToAction/CallToAction";
import PropTypes from "prop-types";
import { useState } from "react";

const Tile = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleBoxHover = (e) => {
    e.preventDefault();
    setIsHovered(!isHovered);
  };
  const handleBoxClick = () => {
    window.location = props.to;
  };

  return (
    <div
      className="tile"
      onClick={handleBoxClick}
      onMouseEnter={handleBoxHover}
      onMouseLeave={handleBoxHover}
    >
      <div className="spacer" />
      <div className="content">
        <p>{props.children}</p>
        <CallToAction to={props.to} label={props.label} mailTo={true} />
      </div>
    </div>
  );
};

Tile.propTypes = {
  children: PropTypes.string,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mailTo: PropTypes.bool,
};

Tile.defaultProps = {
  mailTo: false,
};

const WorkWithUs = (props) => {
  return (
    <section className="homepage-work-with-us">
      <div
        className="upper-row"
        style={{
          backgroundImage: "url(" + props.leftTile.image?.file.url + ")",
        }}
      >
        <Tile
          to={props.leftTile.destination}
          label={props.leftTile.label}
          mailTo={true}
        >
          {props.leftTile.title}
        </Tile>
      </div>
      <div
        className="d-block d-lg-none image"
        style={{
          backgroundImage: "url(" + props.leftTile.image?.file.url + ")",
        }}
      />
      <div className="lower-row">
        <Tile
          to={props.rightTile.destination}
          label={props.rightTile.label}
          mailTo={true}
        >
          {props.rightTile.title}
        </Tile>
      </div>
    </section>
  );
};

WorkWithUs.propTypes = {
  leftTile: PropTypes.object.isRequired,
  rightTile: PropTypes.object.isRequired,
};

export default WorkWithUs;
