import * as React from "react";
import "./Posts.scss";
import CallToAction from "../../CallToAction/CallToAction";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Post = (props) => {
  const handleBoxClick = () => {
    window.open(props.to, "_blank").focus();
  };
  return (
    <article className="post" onClick={handleBoxClick}>
      <div className="spacer" />
      <div className="image">
        <GatsbyImage image={getImage(props.image)} alt={props.title} />
      </div>
      <div className="content">
        <p>{props.title}</p>
        <CallToAction
          to={props.to}
          label={props.label}
          mailTo={true}
          underlined={false}
        />
      </div>
    </article>
  );
};

Post.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

const Posts = (props) => {
  return (
    <section className="homepage-posts">
      <div className="container-fluid">
        <h4>{props.title}</h4>
        <div className="posts-list">
          {props.posts.map((item, index) => (
            <Post
              key={`post-${index}`}
              title={item.title}
              label={item.label}
              to={item.destination}
              image={item.image}
            />
          ))}
        </div>
        <CallToAction to={props.to} label={props.label} mailTo={true} />
      </div>
    </section>
  );
};

Posts.propTypes = {
  title: PropTypes.string.isRequired,
  posts: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Posts;
