import * as React from "react";
import "./Presentation.scss";
import PropTypes from "prop-types";

const Presentation = (props) => {
  return (
    <section className="d-xl-flex justify-content-end container-fluid">
      <div className="presentation-text">{props.children}</div>
    </section>
  );
};

Presentation.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Presentation;
