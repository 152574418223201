import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import CallToAction from "../../CallToAction/CallToAction";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import arrowDownIcon from "../../../assets/images/hevelop/arrow-down.svg";
import "./ReportHeader.scss";

const ReportHeader = (props) => {
  return (
    <Container fluid>
      <Row className="report-header">
        <Col className="report-header-column col-12 col-lg-6">
          <div className="report-header-title">{props.title}</div>
          <div className="report-header-subtitle w-lg-75">{props.subtitle}</div>
          {props.link && props.link && (
            <CallToAction to={props.link} label={props.linkLabel} />
          )}
          {!(props.link && props.link) && (
            <img
              className="report-header-arrow-icon"
              src={arrowDownIcon}
              alt="arrow down icon"
            />
          )}
        </Col>
        <Col className="col-12 col-lg-6">
          {props.mainImage && (
            <GatsbyImage
              className="report-header-image"
              image={getImage(props.mainImage)}
              alt={props.mainImage.title}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

ReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  mainImage: PropTypes.object.isRequired,
};

export default ReportHeader;
