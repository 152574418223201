import * as React from "react";
import "./Hero.scss";
import CallToAction from "../../CallToAction/CallToAction";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = (props) => {
  return (
    <section className="homepage-hero">
      <div className="background">
        <div className="sizer" />
      </div>
      <div className="foreground">
        <div className="content container-lg">
          <div className="wrapper">
            <h1>{props.title}</h1>
            <CallToAction to={props.to} label={props.label} />
          </div>
        </div>
        <div className="image">
          <div className="image-container">
            <GatsbyImage alt={props.title} image={getImage(props.image)} />
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Hero;
