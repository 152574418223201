import * as React from "react";
import "./Homepage.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import Hero from "./Hero/Hero";
import Tiles from "./Tiles/Tiles";
import Services from "./Services/Services";
import Presentation from "./Presentation/Presentation";
import ModusOperandi from "./ModusOperandi/ModusOperandi";
import ReportHeader from "../Report/ReportHeader/ReportHeader";
import FeaturedCaseStudies from "./FeaturedCaseStudies/FeaturedCaseStudies";
import ClientsList from "./ClientsList/ClientsList";
import HeroText from "./HeroText/HeroText";
import WorkWithUs from "./WorkWithUs/WorkWithUs";
import Posts from "./Posts/Posts";
import query from "./query";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../Utils/Language";
import PropTypes from "prop-types";

const pToBr = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <>
        {children}
        <br />
      </>
    ),
  },
};

const Homepage = (props) => {
  const data = query();
  const homepage = filterDataByLanguage(props.lang, data.allContentfulHomepage);
  const caseStudies = filterListDataByLanguage(
    props.lang,
    data.allContentfulCaseHistoryItem
  );

  const presentation = documentToReactComponents(
    JSON.parse(homepage.presentation.raw)
  );
  const serviceTitle = documentToReactComponents(
    JSON.parse(homepage.servicesTitle.raw),
    pToBr
  );
  const modusTitle = documentToReactComponents(
    JSON.parse(homepage.methodTitle.raw),
    pToBr
  );
  const heroText = documentToReactComponents(JSON.parse(homepage.heroText.raw));

  return (
    <div className="homepage">
      <Hero
        title={homepage.pageTitle}
        label={homepage.heroCtaLabel}
        image={homepage.heroImage}
        to={homepage.heroDestination}
      />
      <Presentation>
        <>{presentation}</>
      </Presentation>
      {props.lang === "it" && (
        <ReportHeader
          title={homepage.reportTitle}
          subtitle={homepage.reportSubtitle}
          link={homepage.reportLink}
          linkLabel={homepage.reportLinkLabel}
          mainImage={homepage.reportMainImage}
        />
      )}
      <Tiles
        leftTile={homepage.leftTile}
        rightTile={homepage.rightTile}
        decorationBg={homepage.rightTileRow1Background}
      />
      <Services
        services={homepage.serviceList}
        label={homepage.servicesCtaLabel}
        to={homepage.servicesDestination}
      >
        <>{serviceTitle}</>
      </Services>
      <ModusOperandi
        modi={homepage.methods}
        label={homepage.methodCtaLabel}
        to={homepage.methodCtaDestination}
      >
        <>{modusTitle}</>
      </ModusOperandi>
      <FeaturedCaseStudies
        topCaseStudy={caseStudies[0]}
        bottomCaseStudy={caseStudies[1]}
        generalCaseStudy={homepage.caseStudy}
      />
      <ClientsList
        clients={homepage.clientImages}
        title={homepage.clientTitle}
      />
      <HeroText>{heroText}</HeroText>
      <WorkWithUs
        leftTile={homepage.workWithUsTile1}
        rightTile={homepage.workWithUsTile2}
      />
      <Posts
        title={homepage.blogTitle}
        posts={homepage.posts}
        to={homepage.blogCtaDestination}
        label={homepage.blogCtaLabel}
      />
    </div>
  );
};

Homepage.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Homepage;
