import * as React from "react";
import "./FeaturedCaseStudies.scss";
import handVector from "./images/hand.svg";
import CallToAction from "../../CallToAction/CallToAction";
import Link from "gatsby-link";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeaturedCaseStudies = (props) => {
  return (
    <section className="homepage-feature-case-studies">
      <div className="upper-row container-fluid">
        <header>
          <h3>{props.generalCaseStudy.name}</h3>
          <CallToAction
            to={
              props.generalCaseStudy.path.length
                ? props.generalCaseStudy.path
                : "#"
            }
            label={props.generalCaseStudy.value}
            inverted
          />
          <img
            className="d-none hand-point"
            src={handVector}
            alt="Hevelop Hand"
          />
        </header>
        <div className="tile">
          <div className="wrapper">
            <GatsbyImage
              image={getImage(props.topCaseStudy.customerImage)}
              alt={props.topCaseStudy.customerName}
            />
            <Link to={props.topCaseStudy.customerLink} className="content">
              <p className="title">
                <span>{props.topCaseStudy.customerName}</span>{" "}
                <i className="px-arrow-right" />
              </p>
              <p>
                {props.topCaseStudy.customerDescription.customerDescription}
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="lower-row container-lg">
        <div className="image">
          <GatsbyImage
            image={getImage(props.bottomCaseStudy.customerImage)}
            alt={props.bottomCaseStudy.customerName}
          />
        </div>
        <div className="tiles">
          <div className="tile-element left">
            <div className="bg" style={{ backgroundColor: "#00EB64" }}>
              <div className="spacer" />
            </div>
          </div>
          <div className="tile-element right">
            <div className="bg" style={{ backgroundColor: "#DFDFDF" }}>
              <div className="spacer" />
            </div>
          </div>
        </div>
        <Link to={props.bottomCaseStudy.customerLink} className="content">
          <p className="title">
            {props.bottomCaseStudy.customerName}{" "}
            <i className="px-arrow-right" />
          </p>
          <p>{props.bottomCaseStudy.customerDescription.customerDescription}</p>
        </Link>
      </div>
    </section>
  );
};

FeaturedCaseStudies.propTypes = {
  topCaseStudy: PropTypes.object.isRequired,
  bottomCaseStudy: PropTypes.object.isRequired,
  generalCaseStudy: PropTypes.object.isRequired,
};

export default FeaturedCaseStudies;
